import React from "react";
import imgMultizone from "../../../static/images/tools/multizone.svg";
import imgEasySetup from "../../../static/images/tools/easy-setup.svg";
import imgFreeTrial from "../../../static/images/tools/free-trial.svg";
import imgSupport from "../../../static/images/tools/support.svg";
import imgTransparency from "../../../static/images/tools/transparency.svg";
import imgVeryFast from "../../../static/images/tools/very-fast.svg";
import useTranslations from "../useTranslations";

const Tools = () => {
  const { tools } = useTranslations();

  return (
    <div className="py-16 sm:py-24 bg-white">
      <div className="max-w-6xl mx-auto px-4 sm:px-8 lg:px-16">
        <h2 className="text-3xl max-w-3xl mx-auto font-extrabold text-gray-900 text-center">
          {tools.title}
        </h2>
        <dl className="space-y-10 mt-12 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-8">
          <div>
            <dt>
              <div className="flex w-100 justify-center">
                <img className="h-32 w-32" src={imgMultizone} alt="multizone" />
              </div>
              <p className="text-center mt-5 text-lg leading-6 font-medium text-gray-900">
                {tools.multizones.title}
              </p>
            </dt>
            <dd className="text-center mt-2 text-base text-gray-500">
              {tools.multizones.description}
            </dd>
          </div>

          <div>
            <dt>
              <div className="flex w-100 justify-center">
                <img
                  className="h-32 w-32"
                  src={imgEasySetup}
                  alt="Easy setup"
                />
              </div>
              <p className="text-center mt-5 text-lg leading-6 font-medium text-gray-900">
                {tools.easySetup.title}
              </p>
            </dt>
            <dd className="text-center mt-2 text-base text-gray-500">
              {tools.easySetup.description}
            </dd>
          </div>

          <div>
            <dt>
              <div className="flex w-100 justify-center">
                <img
                  className="h-32 w-32"
                  src={imgTransparency}
                  alt="transparency"
                />
              </div>
              <p className="text-center mt-5 text-lg leading-6 font-medium text-gray-900">
                {tools.transparency.title}
              </p>
            </dt>
            <dd className="text-center mt-2 text-base text-gray-500">
              {tools.transparency.description}
            </dd>
          </div>

          <div>
            <dt>
              <div className="flex w-100 justify-center">
                <img className="h-32 w-32" src={imgVeryFast} alt="very fast" />
              </div>
              <p className="text-center mt-5 text-lg leading-6 font-medium text-gray-900">
                {tools.veryFast.title}
              </p>
            </dt>
            <dd className="text-center mt-2 text-base text-gray-500">
              {tools.veryFast.description}
            </dd>
          </div>

          <div>
            <dt>
              <div className="flex w-100 justify-center">
                <img
                  className="h-32 w-32"
                  src={imgFreeTrial}
                  alt="Free Trial"
                />
              </div>
              <p className="text-center mt-5 text-lg leading-6 font-medium text-gray-900">
                {tools.freeTrial.title}
              </p>
            </dt>
            <dd className="text-center mt-2 text-base text-gray-500">
              {tools.freeTrial.description}
            </dd>
          </div>

          <div>
            <dt>
              <div className="flex w-100 justify-center">
                <img className="h-32 w-32" src={imgSupport} alt="support" />
              </div>
              <p className="text-center mt-5 text-lg leading-6 font-medium text-gray-900">
                {tools.support.title}
              </p>
            </dt>
            <dd className="text-center mt-2 text-base text-gray-500">
              {tools.support.description}
            </dd>
          </div>
        </dl>
      </div>
    </div>
  );
};

export default Tools;
