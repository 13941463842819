import React from "react";
import { HiEye, HiFingerPrint, HiLightningBolt } from "react-icons/hi";
import { BiCookie } from "react-icons/all";

import useTranslations from "../useTranslations";

const ServerSidePros = () => {
  const { serverSidePros } = useTranslations();
  const features = [
    {
      name: serverSidePros.features.cookies.title,
      description: serverSidePros.features.cookies.description,
      icon: BiCookie,
    },
    {
      name: serverSidePros.features.visitors.title,
      description: serverSidePros.features.visitors.description,
      icon: HiEye,
    },
    {
      name: serverSidePros.features.performance.title,
      description: serverSidePros.features.performance.description,
      icon: HiLightningBolt,
    },
    {
      name: serverSidePros.features.security.title,
      description: serverSidePros.features.security.description,
      icon: HiFingerPrint,
    },
  ];

  return (
    <div className="py-16 sm:py-24 bg-gray-50 relative">
      <div className="max-w-6xl mx-auto px-4 sm:px-8 lg:px-16">
        <div className="lg:text-center">
          <h2 className="text-base text-cyan-600 font-semibold tracking-wide uppercase">
            {serverSidePros.header}
          </h2>
          <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            {serverSidePros.title}
          </p>
          <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
            {serverSidePros.subtitle}
          </p>
        </div>

        <div className="mt-10">
          <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
            {features.map((feature) => (
              <div key={feature.name} className="relative">
                <dt>
                  <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-cyan-600 text-white">
                    <feature.icon className="h-6 w-6" aria-hidden="true" />
                  </div>
                  <p className="ml-16 text-lg leading-6 font-medium text-gray-900">
                    {feature.name}
                  </p>
                </dt>
                <dd className="mt-2 ml-16 text-base text-gray-500">
                  {feature.description}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
};

export default ServerSidePros;
