import React from "react";
import { HiCheck, HiX } from "react-icons/hi";
import useTranslations from "../useTranslations";
import reactStringReplace from "react-string-replace";

const Checklist = () => {
  const { checkList } = useTranslations();
  const pros = [
    {
      name: checkList.allInOne.features.webPerf.title,
      description: checkList.allInOne.features.webPerf.description,
    },
    {
      name: checkList.allInOne.features.cookies.title,
      description: checkList.allInOne.features.cookies.description,
    },
    {
      name: checkList.allInOne.features.security.title,
      description: checkList.allInOne.features.security.description,
    },
    {
      name: checkList.allInOne.features.facebookCAPI.title,
      description: checkList.allInOne.features.facebookCAPI.description,
    },
  ];

  const cons = [
    {
      name: checkList.whatYouHate.features.architecture.title,
      description: checkList.whatYouHate.features.architecture.description,
    },
    {
      name: checkList.whatYouHate.features.tickets.title,
      description: checkList.whatYouHate.features.tickets.description,
    },
    {
      name: checkList.whatYouHate.features.monitor.title,
      description: checkList.whatYouHate.features.monitor.description,
    },
    {
      name: checkList.whatYouHate.features.pricing.title,
      description: checkList.whatYouHate.features.pricing.description,
    },
  ];

  return (
    <div className="bg-gray-50 py-16 sm:py-24 relative">
      <div className="max-w-6xl mx-auto px-4 sm:px-8 lg:px-16">
        <div>
          <h2 className="text-base font-semibold text-cyan-600 uppercase tracking-wide">
            {checkList.header}
          </h2>
          <p className="mt-2 text-3xl font-extrabold text-gray-900">
            {reactStringReplace(checkList.title, "<br/>", () => (
              <br />
            ))}
          </p>
          <p className="mt-4 text-lg text-gray-500">{checkList.subtitle}</p>
        </div>
        <div className="flex flex-col gap-10 lg:flex-row lg:gap-5 mt-12 lg:mt-8">
          <div className="flex-1">
            <p className="mb-8 text-2xl font-bold text-cyan-600">
              {checkList.allInOne.title}
            </p>
            <dl className="flex flex-col gap-5">
              {pros.map((feature) => (
                <div key={feature.name} className="relative">
                  <dt>
                    <HiCheck
                      className="absolute h-6 w-6 text-cyan-600"
                      aria-hidden="true"
                    />
                    <p className="ml-9 text-lg leading-6 font-medium text-gray-900">
                      {feature.name}
                    </p>
                  </dt>
                  <dd className="mt-1 ml-9 text-base text-gray-500">
                    {feature.description}
                  </dd>
                </div>
              ))}
            </dl>
          </div>
          <div className="flex-1">
            <p className="mb-8 text-2xl font-bold text-cyan-600">
              {checkList.whatYouHate.title}
            </p>
            <dl className="flex flex-col gap-5">
              {cons.map((feature) => (
                <div key={feature.name} className="relative">
                  <dt>
                    <HiX
                      className="absolute h-6 w-6 text-red-600"
                      aria-hidden="true"
                    />
                    <p className="ml-9 text-lg leading-6 font-medium text-gray-900">
                      {feature.name}
                    </p>
                  </dt>
                  <dd className="mt-2 ml-9 text-base text-gray-500">
                    {feature.description}
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Checklist;
