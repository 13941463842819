import React from "react";
import useTranslations from "../useTranslations";
import reactStringReplace from "react-string-replace";
import launch from "../../../static/images/home/launch.svg";

const Hero = () => {
  const { home, common } = useTranslations();

  return (
    <div className="bg-white py-16 sm:py-24">
      <div className="max-w-6xl mx-auto px-4 sm:px-8 lg:px-16 flex flex-col md:flex-row md:gap-10">
        <div className="flex flex-col justify-center md:justify-start flex-1 items-center md:items-start">
          <h1 className="tracking-tight font-extrabold text-center md:text-left text-gray-900 text-5xl lg:text-6xl">
            {reactStringReplace(home.hero.title, "{{gtmServerSide}}", () => (
              <span className="text-cyan-600">GTM server-side</span>
            ))}
          </h1>
          <p className="mt-6 md:mt-3 max-w-md text-center md:text-left mx-auto text-xl text-gray-500 md:text-xl md:mt-5 md:max-w-3xl">
            {home.hero.subtitle}
          </p>
          <div className="mt-10 flex items-center gap-5 flex-wrap justify-center md:justify-start">
            <div>
              <a
                target="_blank"
                rel="noopener"
                href={`${process.env.GATSBY_APP_URL}/signup`}
                className="w-full flex items-center justify-center px-8 py-3 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-cyan-600 hover:bg-cyan-700 md:py-4 md:text-lg md:px-10"
              >
                {home.hero.freeTrial}
              </a>
            </div>
            <div>
              <a
                target="_blank"
                rel="noopener"
                href={common.bookDemoLink}
                className="w-full flex items-center justify-center px-8 py-3 border border-gray-300 shadow-sm text-base font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 md:py-4 md:text-lg md:px-10 cursor-pointer"
              >
                {home.hero.bookDemo}
              </a>
            </div>
          </div>
        </div>
        <div className="hidden md:flex flex-1 justify-center items-center mb-8 md:mb-0">
          <img
            className="w-2/3 md:w-full"
            src={launch}
            alt="Start with GTM server-side"
          />
        </div>
      </div>
    </div>
  );
};

export default Hero;
