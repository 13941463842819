import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import useTranslations from "./useTranslations";

const LogoClouds = () => {
  const { home } = useTranslations();
  return (
    <div className="relative bg-white">
      <div className="max-w-6xl mx-auto px-4 sm:px-8 lg:px-16 pb-16 sm:pb-20">
        <p className="text-center text-3xl font-extrabold text-gray-900 tracking-wider">
          {home.logos.title}
        </p>
        <div className="mt-12 lg:mt-16 ">
          <div className="grid lg:grid-cols-4 sm:grid-cols-3 grid-cols-2 gap-4 lg:-ml-4">
            <div className="flex justify-center items-center bg-gray-100 rounded-lg px-5 py-1">
              <StaticImage
                className="w-full"
                src="../../static/images/logo/accor_400_133.png"
                alt="Logo Accor"
                placeholder="blurred"
              />
            </div>
            <div className="flex justify-center items-center bg-gray-100 rounded-lg px-5 py-1">
              <StaticImage
                className="w-full"
                src="../../static/images/logo/orange_bank_400_133.png"
                alt="Logo Orange Bank"
                placeholder="blurred"
              />
            </div>
            <div className="flex justify-center items-center bg-gray-100 rounded-lg px-5 py-1">
              <StaticImage
                className="w-full"
                src="../../static/images/logo/voyage_prive_400_133.png"
                alt="Logo Voyage prive"
                placeholder="blurred"
              />
            </div>
            <div className="flex justify-center items-center bg-gray-100 rounded-lg px-5 py-1">
              <StaticImage
                className="w-full"
                src="../../static/images/logo/maisons_du_monde_400_133.png"
                alt="Logo maisons du monde"
                placeholder="blurred"
              />
            </div>
            <div className="flex justify-center items-center bg-gray-100 rounded-lg px-5 py-1">
              <StaticImage
                className="w-full"
                src="../../static/images/logo/leclerc_400_133.png"
                alt="Logo leclerc"
                placeholder="blurred"
              />
            </div>
            <div className="flex justify-center items-center bg-gray-100 rounded-lg px-5 py-1">
              <StaticImage
                className="w-full"
                src="../../static/images/logo/jacquemus_400_133.png"
                alt="Logo jacquemus"
                placeholder="blurred"
              />
            </div>
            <div className="sm:flex hidden justify-center items-center bg-gray-100 rounded-lg px-5 py-1">
              <StaticImage
                className="w-full"
                src="../../static/images/logo/engie_400_133.png"
                alt="Logo engie"
                placeholder="blurred"
              />
            </div>
            <div className="sm:flex hidden justify-center items-center bg-gray-100 rounded-lg px-5 py-1">
              <StaticImage
                className="w-full"
                src="../../static/images/logo/yves_rocher_400_133.png"
                alt="Logo yves rocher"
                placeholder="blurred"
              />
            </div>
            <div className="sm:flex hidden justify-center items-center bg-gray-100 rounded-lg px-5 py-1">
              <StaticImage
                className="w-full"
                src="../../static/images/logo/micromania_400_133.png"
                alt="Logo micromania"
                placeholder="blurred"
              />
            </div>
            <div className="lg:flex hidden justify-center items-center bg-gray-100 rounded-lg px-5 py-1">
              <StaticImage
                className="w-full"
                src="../../static/images/logo/o2_400_133.png"
                alt="Logo o2"
                placeholder="blurred"
              />
            </div>
            <div className="lg:flex hidden justify-center items-center bg-gray-100 rounded-lg px-5 py-1">
              <StaticImage
                className="w-full"
                src="../../static/images/logo/but_400_133.png"
                alt="Logo but"
                placeholder="blurred"
              />
            </div>
            <div className="lg:flex hidden justify-center items-center bg-gray-100 rounded-lg px-5 py-1">
              <StaticImage
                className="w-full"
                src="../../static/images/logo/letudiant_400_133.png"
                alt="Logo letudiant"
                placeholder="blurred"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogoClouds;
