import React from "react";
import Hero from "../components/home/hero";
import Tools from "../components/pricing/tools";
import ServerSidePros from "../components/home/serverSidePros";
import Checklist from "../components/home/checklist";
import CtaSection from "../components/ctaSection";
import useTranslations from "../components/useTranslations";
import LogoClouds from "../components/logoClouds";
import HelmetHeader from "../components/helmetHeader";

const Index = ({ location }) => {
  const { home, lang } = useTranslations();
  return (
    <div>
      <HelmetHeader
        location={location}
        lang={lang}
        title={home.metas.title}
        metas={[{ name: "description", content: home.metas.description }]}
      />
      <Hero />
      <LogoClouds />
      <ServerSidePros />
      <Tools />
      <Checklist />
      <CtaSection />
    </div>
  );
};

export default Index;
